<template>
  <div>
    <d-body-top-bar
      :title="$t('admin.user-club.new-admin.' + getTitle)"
    >
      <div slot="tree">
        <label class="pointer link-area"
               @click="$router.push({ name: 'clubs'})"
        >
          {{ $t('admin.clubs.list') }} /
        </label>
        <label
          class="pointer inactive-tree link-area ml-1"
          @click="gotToClubDetails"
        >
          {{ $t('admin.club_detail.detail_club') }} /
        </label>
        <label class="inactive-tree ml-1">
          {{ isUpdate ? $t('admin.user-club.new-admin.title-update') : $t('admin.user-club.new-admin.title-add') }}
        </label>
      </div>

    </d-body-top-bar>

    <div class="container-fluid"
         v-if="user"
    >
      <validation-observer
        ref="userAddValidator"
        slim
      >
        <b-card>
          <b-row>
            <b-col cols="3" class="mt-2 mb-2">
              <label class="field-name-class">
                {{ $t('admin.user-club.new-admin.username') }}
              </label>
              <d-text-field
                v-model.trim="user.username"
                :rules="validation.username"
                size="sm"
                class-name="background-light-blue-inputs"
                error-text="validation.text-input-three-characters"
              />
            </b-col>
            <b-col cols="3" class="mt-2 mb-2">
              <label class="field-name-class">
                {{ $t('admin.user-club.new-admin.number') }}
              </label>
              <d-text-field
                v-model.trim="user.phoneNumber"
                size="sm"
                class-name="background-light-blue-inputs"
                error-text="validation.custom-client.phoneNumber"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3" class="mt-2 mb-2">
              <label class="field-name-class">
                {{ $t('admin.user-club.new-admin.first-name') }}
              </label>
              <d-text-field
                v-model.trim="user.firstName"
                :rules="validation.firstName"
                size="sm"
                class-name="background-light-blue-inputs"
                error-text="validation.text-input-three-characters"
              />
            </b-col>
            <b-col cols="3" class="mt-2 mb-2">
              <label class="field-name-class">
                {{ $t('admin.user-club.new-admin.last-name') }}
              </label>
              <d-text-field
                v-model.trim="user.lastName"
                :rules="validation.lastName"
                size="sm"
                error-text="validation.text-input-three-characters"
                class-name="background-light-blue-inputs"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3" class="mt-2 mb-2">
              <div class="btn-full-width field-name-class">
                {{ $t('admin.user-club.new-admin.profile') }}
              </div>
              <div class="mt-2">
                <div
                  v-if="userProfiles"
                >
                  <b-row
                    class="mb-3 checkbox-container"
                  >
                    <b-col
                      v-for="(profile, i) in userProfiles"
                      :key="'profile' + i"
                      cols="6"
                      class="d-flex justify-content-start mt-2"
                    >
                      <label class="m-0">{{ profile.name }}</label>
                      <input
                        v-model="user.accessProfiles"
                        :id="`checkbox-${profile['@id']}`"
                        :value="profile['@id']"
                        :name="profile['@id']"
                        class="mx-2"
                        type="checkbox"
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col cols="3" class="mt-2 mb-2" v-if="isUpdate">
              <label class="field-name-class">
                {{ $t('admin.user-club.new-admin.mail') }}
              </label>
              <d-text-field
                v-model.trim="user.email"
                :rules="validation.email"
                size="sm"
                error-text="validation.custom-client.email"
                class-name="background-light-blue-inputs"
              />
            </b-col>
          </b-row>
          <b-row v-if="!isUpdate">
            <b-col cols="3" class="mt-2 mb-2">
              <label class="field-name-class">
                {{ $t('admin.user-club.new-admin.mail') }}
              </label>
              <d-text-field
                v-model.trim="user.email"
                :rules="validation.email"
                size="sm"
                error-text="validation.custom-client.email"
                class-name="background-light-blue-inputs"
              />
            </b-col>
            <b-col cols="3" class="mt-2 mb-2">
              <label class="field-name-class">
                {{ $t('admin.user-club.new-admin.password') }}
              </label>
              <d-text-field
                v-model="user.password"
                :rules="validation.password"
                size="sm"
                error-text="validation.text-input-eight-characters"
                class-name="background-light-blue-inputs"
              />
            </b-col>
          </b-row>
          <b-col cols="12" class="mt-4">
            <b-row cols="12">
              <b-col cols="6" class="mt-2 mb-2 pl-0">
                <div
                  class="d-btn-small btn text-center d-btn-danger btn-full-width"
                  @click="gotToClubDetails"
                >
                  {{ $t('admin.user-club.new-admin.canceled') }}
                </div>
              </b-col>
              <b-col cols="6" class="mt-2 mb-2">
                <div
                  class="d-btn-small btn text-center d-btn-primary btn-full-width"
                  @click="validate"
                >
                  {{ $t('admin.user-club.new-admin.validate') }}
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-card>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {addUserClub, getUser, getUserAccessProfiles, putUser} from "@api/services/user-club/user-club.api";
import User from "@/classes/User";
import {fromIdToIriReference} from "@/utils/form";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  name: "Index",
  data: () => ({
    userProfiles: null,
    user: null,
    isBusy: false,
    validation: require('@validation/entities/UserClub.json'),

  }),
  computed: {
    getIdClub() {
      return this.$route.params.id;
    },
    isUpdate() {
      return !!this.$route.params.idUser;
    },
    getTitle() {
      return this.isUpdate ? 'update' : 'new';
    }
  },
  methods: {
    loadUser(id) {
      getUser(id)
        .then((response) => {
          this.user = new User(response.data, {serialize: true});
          this.user.accessProfiles = this.getUserClubAccessProfile(response.data);
          delete this.user.avatar;
        })
    },
    validate() {
      this.$refs.userAddValidator.validate()
        .then((valid) => {
          if (valid) {
            if (!this.isBusy) {
              const newUser = new User(this.user, {serialize: true});
              newUser.accessProfiles = this.user.accessProfiles;
              this.isBusy = true;

              if (this.isUpdate) {
                this.updateUser(newUser);
              } else {
                newUser.plainPassword = this.user.password;
                this.addUser(newUser);
              }
            }
          }
        })

    },
    addUser(data) {
      if (data.accessProfiles.length === 0) {
        this.$vs.notify({
          time: 10000,
          title: this.$t('general.actions.notification'),
          text: this.$t('admin.user-club.new-admin.select-role'),
          color: 'danger',
          position: 'top-right'
        });
        this.isBusy = false;
      } else {
        delete data.avatar;
        addUserClub(data)
          .then(() => {
            this.gotToClubDetails();
          })
          .catch((error) => {
            this.showErrors(error);
          })
          .finally(() => {
            this.isBusy = false;
          })
        ;
      }
    },
    showErrors(error) {
      if ("undefined" !== typeof error.data.violations) {
        for (const violation of error.data.violations) {
          this.$vs.notify({
            time: 10000,
            title: violation.propertyPath,
            text: violation.message,
            color: 'danger',
            position: 'top-right'
          });
        }
      } else {
        this.$vs.notify({
          time: 10000,
          title: this.$t('general.actions.notification'),
          text: error.data["hydra:description"],
          color: 'danger',
          position: 'top-right'
        });
      }
    },
    hasAtLeastAnAccessProfilePerClub(userAccessProfiles) {
      for (const userProfile of this.userProfiles) {
        const findUserProfile = userAccessProfiles.find(el => el === userProfile['@id']);
        if (isNotUndefinedAndNotNull(findUserProfile)) {
          return true;
        }
      }

      return false;
    },
    updateUser(data) {
      if (false === this.hasAtLeastAnAccessProfilePerClub(data.accessProfiles)) {
        this.$vs.notify({
          time: 10000,
          title: this.$t('general.actions.notification'),
          text: this.$t('admin.user-club.new-admin.select-role'),
          color: 'danger',
          position: 'top-right'
        });
        this.isBusy = false;
      } else {
        delete data.avatar;
        putUser(this.$route.params.idUser, data)
          .then(() => {
            this.gotToClubDetails();
          })
          .catch((error) => {
            this.showErrors(error);
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },
    loadUserProfiles() {
      return new Promise((resolve, reject) => {
        getUserAccessProfiles(20, 1, '', this.getIdClub)
          .then((response) => {
            this.userProfiles = response.data['hydra:member'].sort((b, a) => a.permission - b.permission);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUserClubAccessProfile(item) {
      const array = [];

      for (let profile of item.accessProfiles) {
        array.push(profile["@id"]);
      }
      return array;
    },
    gotToClubDetails() {
      this.$router.push({name: 'clubs_detail', params: {id: this.getIdClub}});
    }
  },
  mounted() {
    this.loadUserProfiles()
      .then(() => {
        if (this.isUpdate) {
          this.loadUser(this.$route.params.idUser);
        } else {
          this.user = new User();
          this.user.accessProfiles = [this.userProfiles[0]["@id"]];
          this.user.clubs = [fromIdToIriReference('/clubs', this.getIdClub)];
          delete this.user.avatar;
        }
      })
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/checkbox/_checkbox";
</style>
